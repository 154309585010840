import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            userGroup: null,
            userGroupStatus: null,
        }
    },
    mutations: {
        setUserGroup(state, payload) {
            state.userGroup = payload
        },
        setUserGroupStatus(state, payload) {
            state.userGroupStatus = payload
        },
    },
    actions: {
        setUserGroup({ commit }, params) {
            commit('setUserGroup', params)
        },
        setUserGroupStatus({ commit }, params) {
            commit('setUserGroupStatus', params)
        },
        async getUserGroup({ commit, state }, params) {
            return await Api().get(
                `user-groups${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postUserGroup({ commit, state }, params) {
            return await Api().post(
                `/user-groups`, params
            ).then((res) => {
                return res.data
            })
        },
        async editUserGroup({ commit, state }, params) {
            return await Api().put(
                `/user-groups/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getUserGroup(state) {
            return state.userGroup;
        },
        getUserGroupStatus(state) {
            return state.userGroupStatus;
        },
    }
}