import { createStore } from 'vuex'
// import axios from "axios";

import requestDocument from "./modules/document-management/requestDocument";
import document from "./modules/document-management/document";
import docFile from './modules/document-management/docFile';
import docApproval from './modules/document-management/docApproval';
import docAccessRight from './modules/document-management/docAccessRight';
import detailDocAccessRight from './modules/document-management/detailDocAccessRight';
import regulationList from './modules/regulation/regulationList';
import regulationDetail from './modules/regulation/regulationDetail';
import regulationImpl from './modules/regulation/regulationImpl';
import regulationDoc from './modules/regulation/regulationDoc';
import authentication from './modules/auth/authentication';
import department from './modules/department';
import unitBisnis from './modules/unitBisnis';
import docType from './modules/document-management/docType';
import module from './modules/module';
import templateDocs from './modules/document-management/templateDocs';
import risk from './modules/risk-management/risk';
import riskIdentification from './modules/risk-management/riskIdentification';
import riskScore from './modules/risk-management/riskScore';
import riskControl from './modules/risk-management/riskControl';
import riskImprovementPlan from './modules/risk-management/riskImprovementPlan';
import riskControlItem from './modules/risk-management/riskControlItem';
import riskControlDetail from './modules/risk-management/riskControlDetail';
import riskScoreIndicator from './modules/risk-management/riskScoreIndicator';
import riskSeverity from './modules/risk-management/riskSeverity';
import asset from './modules/calibration/asset';
import auditPlan from './modules/audit/auditPlan';
import auditChecklist from './modules/audit/auditChecklist';
import processList from './modules/audit/processList';
import scopeActivities from './modules/audit/scopeActivities';
import picChecklist from './modules/audit/picChecklist';
import potentialRisk from './modules/audit/potentialRisk';
import auditRiskControl from './modules/audit/auditRiskControl';
import auditReport from './modules/audit/auditReport';
import findingType from './modules/audit/findingType';
import factorFinding from './modules/audit/factorFinding';
import aberrationStandart from './modules/audit/aberrationStandart';
import faultClassification from './modules/audit/faultClassification';
import faultType from './modules/audit/faultType';
import auditVerificationItem from './modules/audit/auditVerificationItem';
import auditRootCouse from './modules/audit/auditRootCouse';
import auditPrevective from './modules/audit/auditPrevective';
import auditCorrection from './modules/audit/auditCorrection';
import auditImplementation from './modules/audit/auditImplementation';
import auditFinding from './modules/audit/auditFinding';
import auditGroupFinding from './modules/audit/auditGroupFinding';
import capa from './modules/capa/capa';
import capaCorrectionAction from './modules/capa/capaCorrectionAction';
import capaTemporalCorrection from './modules/capa/capaTemporalCorrection';
import capaRootCause from './modules/capa/capaRootCause';
import userGroup from './modules/auth/userGroup';
import userAccess from './modules/auth/userAccess';

export const store = createStore({
    modules: {
        // Documen Management
        requestDocument,
        document,
        docFile,
        docApproval,
        docAccessRight,
        detailDocAccessRight,
        docType,
        templateDocs,
        // Regulation
        regulationList,
        regulationDetail,
        regulationImpl,
        regulationDoc,
        // Risk Management
        risk,
        riskIdentification,
        riskScore,
        riskControl,
        riskSeverity,
        riskImprovementPlan,
        riskControlItem,
        riskControlDetail,
        riskScoreIndicator,
        //Audit 
        auditPlan,
        auditChecklist,
        processList,
        scopeActivities,
        picChecklist,
        potentialRisk,
        auditRiskControl,
        auditVerificationItem,
        auditFinding,
        auditGroupFinding,

        auditReport,
        auditRootCouse,
        auditPrevective,
        auditCorrection,
        auditImplementation,

        findingType,
        factorFinding,
        aberrationStandart,
        faultClassification,
        faultType,

        capa,
        capaCorrectionAction,
        capaTemporalCorrection,
        capaRootCause,

        asset,
        // Utils
        authentication,
        department,
        unitBisnis,
        module,
        userGroup,
        userAccess,
    }
})