import Cookies from "js-cookie";
import Api from '@/services/Api';


const fetchData = async () => {
  // const result = await Api().get(
  //   `navigation?roles=${Cookies.get('rl')}&sorted_by=order&sort=asc`,
  // );

  // let test = [];
  // let menu = result.data.data.forEach(element => {
  //   test.push(element.module)
  //   return element.module;
  // });
  // console.log(test, 'menu');
  // return test;
  // console.log(result.data.data, 'menu');
  const user = JSON.parse(Cookies.get('clientLog'))

  const result = await Api().get(
    `user-access?group=${user.user_group}&limit=9999`,
  );

  const map = new Map();
  const roots = [];
  const data = result.data.data

  // Step 1: Create map for quick lookup
  data.forEach(item => {
    map.set(item.module.id, { ...item.module, items: [] });
  });

  // Step 2: Build hierarchy
  data.forEach(item => {
    const node = map.get(item.module.id);

    if (item.module.parent_id) {
      const parentNode = map.get(item.module.parent_id);
      if (parentNode) {
        parentNode.items.push({
          id: node.id,
          parent_id: node.parent_id,
          name: node.name,
          text: node.text,
          path: node.path,
          order: node.order
        });
        parentNode.items.sort((a, b) => a.order - b.order)
      } else {
        // If parent node does not exist, add this node to roots as a fallback
        roots.push(node);
      }
    } else {
      roots.push(node);
    }
  });
  // sorting
  roots.sort((a, b) => a.order - b.order)

  return roots;
  // return result.data.data;
};

export default fetchData;